import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import AdminFeatureFlagsWithTracking from '~/views/Admin/FeatureFlags'

import { Fragment, type FunctionComponent, useEffect } from 'react'

import useSmoothScrollbar from 'src/hooks/utils/useSmoothScrollbar'

import DocumentsPage from 'src/views/Account/Documents'
import AzureEmissionsExport from 'src/views/Azure/Emissions/Export'
import AzureEmissionsOverview from 'src/views/Azure/Emissions/Overview'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Scrollbar from 'react-smooth-scrollbar'

import { setAuthorised } from '../../actions/authorisedActions'
import { reactPlugin } from '../../configs/appInsights'

/* hooks */
import useAlerts from '../../hooks/composite/useAlerts'

/* utils */
import { classNames } from '../../utils/classNames'
import Contacts from '../../views/Account/Contacts'
import CreditNotes from '../../views/Account/CreditNotes'
import Invoices from '../../views/Account/Invoices'
import MyAccount from '../../views/Account/MyAccount'
import Orders from '../../views/Account/Orders'
import Quotes from '../../views/Account/Quotes'
import Services from '../../views/Account/Services'
import AdminGlobalConfig from '../../views/Admin/Alerts'
import AzureAdvisor from '../../views/Azure/AzureAdvisor/Overview/AzureAdvisor'
import Recommendations from '../../views/Azure/AzureAdvisor/Recommendations/Recommendations'
import AzureBudgets from '../../views/Azure/AzureBudgets'
import AzureDataExport from '../../views/Azure/AzureDataExport'
import AzureDeployments from '../../views/Azure/AzureDeployments/AzureDeployments'
import AzureDeploymentsHistory from '../../views/Azure/AzureDeployments/AzureDeploymentsHistory'
import AzureOverview from '../../views/Azure/AzureOverview/AzureOverview'
import AzureRecurringSpend from '../../views/Azure/AzureRecurringSpend'
import AzureResourceExplorer from '../../views/Azure/AzureResourceExplorer'
import AzureSubscriptions from '../../views/Azure/AzureSubscriptions/AzureSubscriptions'
import AzureTrendsInsights from '../../views/Azure/AzureTrendsInsights'
import NoAzureSplashScreen from '../../views/Azure/NoAzureSplashScreen'
import SubscriptionUsage from '../../views/Azure/SubscriptionUsage/SubscriptionUsage'

/* views */
import Dashboard from '../../views/Dashboard/Dashboard'
import Cart from '../../views/Microsoft 365/Cart/Cart'
import Products from '../../views/Microsoft 365/Products/Products'
import Subscriptions from '../../views/Microsoft 365/Subscriptions/Subscriptions'
import Profile from '../../views/Profile/Profile'
import AzureSecureScore from '../../views/Security/AzureSecureScore'
import M365SecureScore from '../../views/Security/M365SecureScore'
import SignQuote from '../../views/SignQuote'
import Tickets from '../../views/Support/Tickets'
import Training from '../../views/Support/Training'
import Callout from '../Common/Callout'
import AzureLightHouseConnection from '../Drawers/AzureLightHouseConnection'
import BookSecurityReview from '../Drawers/BookSecurityReview'
import CancelService from '../Drawers/CancelService'
import CreditNoteDrawer from '../Drawers/CreditNoteDrawer'
import FeedbackDrawer from '../Drawers/Feedback'
import InvoiceDrawer from '../Drawers/InvoiceDrawer'
import M365SecureScoreInfo from '../Drawers/M365SecureScoreInfo'
import NewAzureDeployment from '../Drawers/NewAzureDeployment'
import NewAzurePlan from '../Drawers/NewAzurePlan'
import NewContact from '../Drawers/NewContact'
import NewTicket from '../Drawers/NewTicket'
import Order from '../Drawers/Order'
import QuoteDrawer from '../Drawers/QuoteDrawer'
import Recommendation from '../Drawers/Recommendation'
import RequestAccountDetailChange from '../Drawers/RequestAccountDetailChange'
import RequestQuote from '../Drawers/RequestQuote'
import Service from '../Drawers/Service'

/* drawers */
import Ticket from '../Drawers/Ticket'
import ErrorBoundaryMessage from '../ErrorBoundaryMessage/ErrorBoundaryMessage'

/* modals */
import AccountsModal from '../Modals/AccountsModal'
import ContactConfirmModal from '../Modals/ContactConfirmModal'
import EditAzureBudgetModal from '../Modals/EditAzureBudgetModal'
import UploadProgressPortal from './FileExplorer/UploadProgressPortal'
import Header from './Header/Header'
import Loading from './Loading/Loading'
import Unauthorised from './Unauthorised/Unauthorised'
import UserCard from './UserCard/UserCard'

const Content: FunctionComponent = (): JSX.Element => {
  const loading = useSelector((state: RootStateOrAny) => state.loading.loading)
  const authorised = useSelector((state: RootStateOrAny) => state.authorised.authorised)
  const user = useSelector((state: RootStateOrAny) => state.user)
  const tenants = useSelector((state: RootStateOrAny) => state.tenants)
  const entitlements = useSelector((state: RootStateOrAny) => state.entitlements)
  const initialized = useSelector((state: RootStateOrAny) => state.initialized)

  /* drawers */
  const ticket = useSelector((state: RootStateOrAny) => state.drawers.ticket)
  const new_ticket = useSelector((state: RootStateOrAny) => state.drawers.new_ticket)
  const new_azure_deployment = useSelector((state: RootStateOrAny) => state.drawers.new_azure_deployment)
  const new_azure_plan = useSelector((state: RootStateOrAny) => state.drawers.new_azure_plan)
  const azure_lighthouse_connect = useSelector((state: RootStateOrAny) => state.drawers.azure_lighthouse_connect)
  const request_quote = useSelector((state: RootStateOrAny) => state.drawers.request_quote)
  const cancel_service = useSelector((state: RootStateOrAny) => state.drawers.cancel_service)
  const creditnote = useSelector((state: RootStateOrAny) => state.drawers.creditnote)
  const quote = useSelector((state: RootStateOrAny) => state.drawers.quote)
  const invoice = useSelector((state: RootStateOrAny) => state.drawers.invoice)
  const service = useSelector((state: RootStateOrAny) => state.drawers.service)
  const order = useSelector((state: RootStateOrAny) => state.drawers.order)
  const new_contact = useSelector((state: RootStateOrAny) => state.drawers.new_contact)
  const book_security_review = useSelector((state: RootStateOrAny) => state.drawers.book_security_review)
  const m365_secure_score_info = useSelector((state: RootStateOrAny) => state.drawers.m365_secure_score_info)
  const recommendation = useSelector((state: RootStateOrAny) => state.drawers.recommendation)
  const change_account_details = useSelector((state: RootStateOrAny) => state.drawers.change_account_details)
  const feedback = useSelector((state: RootStateOrAny) => state.drawers.feedback)

  /* transform fixed elements so they stay in place when scrolling */
  useSmoothScrollbar()

  const location = useLocation()
  const dispatch = useDispatch()

  /* detect route changes */
  useEffect(() => {
    dispatch(setAuthorised(true))
  }, [dispatch, location.pathname])

  // Banner Alert
  const { alerts, dismissAlert } = useAlerts()
  const filteredAlerts = alerts?.filter(
    (config) =>
      config.persistence_mode !== 'auto-close' &&
      config.enabled &&
      (config.appliedToCurrentRoute || config.isGlobal) &&
      !(config.isDismissed && config.persistence_mode === 'dismissable')
  )

  const shouldRenderAzure = initialized && !!tenants?.length && !!entitlements?.length

  return (
    <Fragment>
      <div
        id="content"
        className={classNames('h-full w-full overflow-hidden bg-th-content', 'flex flex-col text-th-text md:rounded-tl-content', loading && 'disable-scroll')}
      >
        {/* boundary for uncaught errors on any view */}
        <AppInsightsErrorBoundary onError={() => <ErrorBoundaryMessage />} appInsights={reactPlugin}>
          <>
            <Header />
            {filteredAlerts.length > 0 && <div className="mt-6" />}
            {filteredAlerts.map((config) => (
              <Callout
                key={config.id}
                text={config.text}
                type={config.variant}
                className="mx-4 mt-2 xs:mx-10"
                onClose={config.persistence_mode === 'dismissable' ? () => dismissAlert(config.id) : undefined}
              />
            ))}
            <div className={classNames('relative flex-auto overflow-x-hidden', loading ? 'overflow-hidden' : 'overflow-y-auto')}>
              {/* views */}
              <Routes>
                <Route path="/" element={<Dashboard />} />
                {/* account */}
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/creditnotes" element={<CreditNotes />} />
                <Route path="/quotes" element={<Quotes />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/orders" element={<Orders />} />
                <Route path="/account" element={<MyAccount />} />
                <Route path="/documents" element={<DocumentsPage />} />
                {/* azure */}
                <Route path="/azure_overview" element={shouldRenderAzure ? <AzureOverview /> : <NoAzureSplashScreen />} />
                <Route path="/azure_subscriptions" element={shouldRenderAzure ? <AzureSubscriptions /> : <NoAzureSplashScreen />} />
                <Route path="/azure_usage" element={shouldRenderAzure ? <SubscriptionUsage /> : <NoAzureSplashScreen />} />
                <Route path="/azure_advisor" element={shouldRenderAzure ? <AzureAdvisor /> : <NoAzureSplashScreen />} />
                <Route path="/azure_advisor/recommendations" element={shouldRenderAzure ? <Recommendations /> : <NoAzureSplashScreen />} />
                <Route path="/azure_deployments" element={shouldRenderAzure ? <AzureDeployments /> : <NoAzureSplashScreen />} />
                <Route path="/azure_deployments/history" element={shouldRenderAzure ? <AzureDeploymentsHistory /> : <NoAzureSplashScreen />} />
                <Route path="/azure_overview/budgets" element={shouldRenderAzure ? <AzureBudgets /> : <NoAzureSplashScreen />} />
                <Route path="/azure_overview/recurring" element={shouldRenderAzure ? <AzureRecurringSpend /> : <NoAzureSplashScreen />} />
                <Route path="/azure_overview/export" element={shouldRenderAzure ? <AzureDataExport /> : <NoAzureSplashScreen />} />
                <Route path="/azure_overview/resource-explorer" element={shouldRenderAzure ? <AzureResourceExplorer /> : <NoAzureSplashScreen />} />
                <Route path="/azure_overview/trends-insights" element={shouldRenderAzure ? <AzureTrendsInsights /> : <NoAzureSplashScreen />} />
                {/* azure sustainability */}
                <Route path="/azure_emissions" element={<AzureEmissionsOverview />} />
                <Route path="/azure_emissions/export" element={<AzureEmissionsExport />} />
                {/* microsoft 365 */}
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/products/:product_id?/:sku?/:sku_id?" element={<Products />} />
                <Route path="/cart" element={<Cart />} />
                {/* support */}
                <Route path="/tickets/:id?" element={<Tickets />} />
                <Route path="/training" element={<Training />} />
                {/* security */}
                <Route path="/azure_secure_score" element={<AzureSecureScore />} />
                <Route path="/m365_secure_score" element={<M365SecureScore />} />
                {/* settings */}
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/sign_quote"
                  element={
                    <div id="signQuoteContent">
                      <Fragment>
                        <div
                          id="contentinternal"
                          className={classNames(
                            'content h-full w-full overflow-hidden bg-th-content',
                            'flex flex-col text-th-text md:rounded-tl-content',
                            loading && 'disable-scroll'
                          )}
                        >
                          <Header />
                          <Scrollbar className="flex-auto">
                            <SignQuote standAlone={true} />
                          </Scrollbar>
                        </div>
                      </Fragment>
                    </div>
                  }
                />
                {/* admin routes */}
                <Route path="/admin/alerts" element={<AdminGlobalConfig />} />
                <Route path="/admin/feature-flags" element={<AdminFeatureFlagsWithTracking />} />
                {/* redirect unhandled endpoints to / */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              {/* page loading animation */}
              {loading && <Loading />}
              {/* page loading animation */}
              {!authorised && location.pathname !== '/' && <Unauthorised />}
            </div>
            {/* cards and modals */}
            {user.open && <UserCard />}
            <AccountsModal />
            <EditAzureBudgetModal />
            <ContactConfirmModal />
            <UploadProgressPortal />
          </>
        </AppInsightsErrorBoundary>
      </div>
      {/* drawers */}
      <div className="h-full border-th-border bg-th-content 2xl:border-l" id="portal-parent">
        {ticket && <Ticket />}
        {new_ticket && <NewTicket />}
        {new_azure_deployment && <NewAzureDeployment />}
        {new_azure_plan && <NewAzurePlan />}
        {azure_lighthouse_connect && <AzureLightHouseConnection />}
        {request_quote && <RequestQuote />}
        {change_account_details && <RequestAccountDetailChange />}
        {cancel_service && <CancelService />}
        {service && <Service />}
        {order && <Order />}
        {new_contact && <NewContact />}
        {creditnote && <CreditNoteDrawer />}
        {invoice && <InvoiceDrawer />}
        {quote && <QuoteDrawer />}
        {m365_secure_score_info && <M365SecureScoreInfo />}
        {book_security_review && <BookSecurityReview />}
        {recommendation && <Recommendation />}
        {feedback && <FeedbackDrawer />}
      </div>
    </Fragment>
  )
}

export default Content
