import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { useQueryClient } from '@tanstack/react-query'

import React, { Fragment, useEffect, useState } from 'react'

import { type AzureUsageExportDataRow } from 'src/types/azure'

import { stopLoading } from 'src/actions/loadingActions'

import api from 'src/constants/api'

import useScheduledExports, { type ScheduledExport } from 'src/hooks/services/microsoft/queries/useScheduledExports'
import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'
import useAccount from 'src/hooks/utils/useAccount'

import Button from 'src/components/Common/Button'
import Heading from 'src/components/Common/Heading'
import Section from 'src/components/Common/Section'
import Spacer from 'src/components/Common/Spacer'
import AzureExportDrawer, { type AzureExportSubmitData } from 'src/components/Drawers/AzureExport'
import ScheduledExportDrawer from 'src/components/Drawers/ScheduledExport'
import AzureUsageExampleTable from 'src/components/Tables/AzureUsageExampleTable'
import ColumnDescriptionsTable from 'src/components/Tables/ColumnDescriptionsTable'
import ScheduledExportsTable from 'src/components/Tables/ScheduledExportsTable'

import { reactPlugin } from 'src/configs/appInsights'

import { useDispatch } from 'react-redux'

import { TABLE_DATA, generateTestDataForTable } from './utils'

const AzureDataExport: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const [testData] = useState<AzureUsageExportDataRow[]>(() => generateTestDataForTable())

  const account = useAccount()
  const tenants = useTenantsQuery({ active: true })
  const scheduledExports = useScheduledExports()

  const [exportDrawerOpen, setExportDrawerOpen] = useState(false)
  const [creatingExportSchedule, setCreatingExportSchedule] = useState(false)
  const [selectedScheduledExport, setSelectedScheduledExport] = useState<ScheduledExport | null>(null)

  const queryClient = useQueryClient()

  const handleUpdateExportData = async (values: AzureExportSubmitData) => {
    setCreatingExportSchedule(true)
    // We have multiple tenants, so we need to flatten them into multiple requests due to API spec
    const requestValues = values.tenantIds.map((tenantId) => ({
      ...values,
      tenantIds: undefined,
      tenantId,
      subscriptions: values.subscriptions.filter((sub) => tenants.data.find((t) => t.id === tenantId)?.subscriptions.some((s) => s.id === sub.subscriptionId))
    }))

    await api.post(`/ms-api/v2/account/${account.id}/azure_usage/set_data_export_schedule`, requestValues)
    await queryClient.invalidateQueries(['scheduled-exports', account.id, tenants.data.map((t) => t.id)])

    setCreatingExportSchedule(false)
    setExportDrawerOpen(false)
  }

  return (
    <Fragment>
      <AzureExportDrawer
        onClose={() => {
          setExportDrawerOpen(false)
        }}
        onSubmit={handleUpdateExportData}
        isLoading={creatingExportSchedule}
        hidden={!exportDrawerOpen}
      />

      <ScheduledExportDrawer onClose={() => setSelectedScheduledExport(null)} scheduledExport={selectedScheduledExport} />
      <Section>
        <Heading text="Exports">
          <Spacer className="hidden sm:block" />
          <Button className="" onClick={() => setExportDrawerOpen(true)} bordered variant="primary">
            Export
          </Button>
        </Heading>
        <ScheduledExportsTable scheduledExports={scheduledExports} onRowClick={(row: ScheduledExport) => setSelectedScheduledExport(row)} />
      </Section>
      <Section>
        <Heading text="Column Descriptions" />
        <ColumnDescriptionsTable columnDescriptions={TABLE_DATA.map(([name, value]) => ({ name, value }))} />
      </Section>
      <Section>
        <Heading text="Data Export Example" />
        <AzureUsageExampleTable data={testData} />
      </Section>
    </Fragment>
  )
}

const AzureDataExportWithTracking = withAITracking(reactPlugin, AzureDataExport, 'Azure Data Export')
export default AzureDataExportWithTracking
