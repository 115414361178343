import { useEffect, useState } from 'react'

import Select, { type OptionTypeBase, type StylesConfig, components } from 'react-select'

import Checkbox from './Checkbox/Checkbox'

/* varients */
enum Variant {
  PRIMARY,
  SECONDARY,
  DEFAULT
}

const VARIANT_MAPS: Record<Variant, Record<string, string | Record<string, string>>> = {
  [Variant.PRIMARY]: {
    backgroundColor: 'var(--primary)',
    color: 'var(--content)',
    '&:hover': {
      backgroundColor: 'var(--highlight)',
      color: 'var(--content)'
    },
    'div div div div div': {
      backgroundColor: 'var(--highlight)',
      borderRadius: '0px',
      color: 'var(--content)'
    }
  },
  [Variant.SECONDARY]: {
    backgroundColor: 'var(--content-secondary)',
    color: 'var(--color)',
    '&:hover': {
      backgroundColor: 'var(--border)',
      color: 'var(--color)'
    },
    'div div div div div': {
      backgroundColor: 'var(--border)',
      borderRadius: '0px',
      color: 'var(--color)'
    }
  },
  [Variant.DEFAULT]: {
    backgroundColor: 'var(--content)',
    color: 'var(--color)',
    '&:hover': {
      backgroundColor: 'var(--content-secondary)',
      color: 'var(--color)'
    },
    'div div div div div': {
      backgroundColor: 'var(--content-secondary)',
      borderRadius: '0px',
      color: 'var(--color)'
    }
  }
}

interface Props {
  options: OptionTypeBase[]
  label: string
  variant: Variant
  multi: boolean
  disabled?: boolean
  defaultValue?: OptionTypeBase
  value?: OptionTypeBase
  bordered?: true
  className?: string
  onChange: (option: OptionTypeBase) => unknown
  disableAll?: boolean
  disabledOptions?: string[]
  isLoading?: boolean
}

const Dropdown = (props: Props): JSX.Element => {
  const { label, variant = Variant.DEFAULT, defaultValue, bordered, multi, disabled } = props

  const [value, setValue] = useState(props.value || defaultValue)
  const [options, setOptions] = useState(props.options)

  useEffect(() => {
    if (props.value) setValue(props.value)
  }, [props.value])

  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  const styles: StylesConfig<OptionTypeBase, boolean> = {
    control: (provided, state) => ({
      ...VARIANT_MAPS[variant],
      display: 'flex',
      alignItems: 'center',
      border: bordered ? '1px solid var(--border)' : 'none',
      borderRadius: '9999px',
      cursor: 'pointer',
      fontFamily: 'HKGrotesque',
      fontSize: '0.875rem',
      padding: '0.5rem 1rem',
      letterSpacing: '0.025em',
      maxWidth: '250px',
      opacity: state.isDisabled ? 0.5 : 1,
      '&:hover': {
        backgroundColor: 'none'
      },
      '&:before': {
        content: '"' + label + (state.hasValue ? ': "' : ' "'),
        marginRight: '0.25rem'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      fontWeight: 'bold',
      input: {
        height: 0,
        width: 0
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...VARIANT_MAPS[variant],
      marginLeft: '0.25rem',
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'none',
        color: 'inherit'
      }
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...VARIANT_MAPS[variant],
      marginLeft: '0.25rem',
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'var(--error)',
        color: 'var(--content)'
      }
    }),
    menu: (provided) => ({
      ...provided,
      ...VARIANT_MAPS[variant],
      borderRadius: '1rem',
      overflow: 'hidden',
      width: '100%',
      minWidth: 'fit-content',
      position: 'absolute',
      zIndex: 9999,
      right: 0,
      fontSize: '0.875rem',
      '&:hover': {
        backgroundColor: 'none'
      }
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
    }),
    option: (provided) => ({
      ...provided,
      ...VARIANT_MAPS[variant],
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '250px',
      textOverflow: 'ellipsis'
    }),
    placeholder: () => ({}),
    singleValue: () => ({
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }),
    multiValue: (provided) => ({
      ...provided,
      ...VARIANT_MAPS[variant],
      margin: '0 2px',
      height: 20,
      fontSize: 11,
      lineHeight: '14px',
      '> div:first-of-type': {
        paddingRight: '6px'
      }
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'var(--error)',
        color: 'var(--content)'
      }
    })
  }

  const getValue = () => {
    if (!multi) {
      const val = options.find((o: OptionTypeBase) => o.value === value?.value && o.label === value?.label)

      if (!val) {
        return defaultValue
      } else {
        return val
      }
    } else {
      if (value) return value.options || value
      if (defaultValue) return defaultValue.options || defaultValue
    }
  }

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <span className={`flex gap-2 ${props.isDisabled ? 'opacity-50' : ''}`} title={props.isDisabled ? 'Limited to 10 selected tags' : ''}>
          {multi && <Checkbox checked={props.isSelected} onChange={() => null} />}
          <label className="cursor-pointer truncate leading-4">{props.label}</label>
        </span>
      </components.Option>
    )
  }

  const MultiValue = ({ index }: any) => {
    return index === 0 ? (
      <span className="h-6 w-6 rounded-full bg-th-primary text-center text-xs leading-6 text-th-white">
        {value ? value.length || 1 : defaultValue ? defaultValue.length : 0}
      </span>
    ) : null
  }

  const GroupHeading = () => {
    return (
      <span
        className="-mt-2 flex cursor-pointer gap-2 bg-th-border px-3 pb-2 pt-3 font-bold"
        onClick={() =>
          setValue((value: OptionTypeBase) => {
            if (!value || value.length === options.length) {
              props.onChange([])
              return []
            } else {
              props.onChange(options)
              return options
            }
          })
        }
      >
        <Checkbox checked={value ? value.length === options.length : defaultValue ? defaultValue.length === options.length : false} onChange={() => null} />
        All
      </span>
    )
  }

  const createGroup = (groupName: string, options: OptionTypeBase[]) => {
    return {
      label: (() => {
        return <span>{groupName}</span>
      })(),
      options: options
    }
  }

  return (
    <Select
      isLoading={props.isLoading}
      placeholder=""
      options={multi && !props.disableAll ? [createGroup('All', options)] : options}
      value={getValue()}
      defaultValue={defaultValue}
      onChange={(option) => {
        setValue(option)
        props.onChange(option)
      }}
      styles={styles}
      isSearchable={false}
      isMulti={multi}
      closeMenuOnSelect={!multi}
      hideSelectedOptions={false}
      className={props.className}
      components={{
        IndicatorSeparator: () => null,
        Option,
        MultiValue,
        GroupHeading
      }}
      isDisabled={disabled}
      menuPlacement="auto"
      filterOption={(option) => option.value !== 'hiddenOption'}
      isOptionDisabled={(option) => props.disabledOptions?.includes(option.value as string) || false}
    />
  )
}

Dropdown.defaultProps = {
  variant: Variant.DEFAULT
}

Dropdown.variant = Variant

export default Dropdown
