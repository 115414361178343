import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAccount from '~/hooks/utils/useAccount'

import api from 'src/constants/api'

import type { FeatureFlag } from '../queries/useAdminFeatureFlagQuery'

interface UpdateFeatureFlagInput {
  id: string
  name: string
  description: string
  enabled: boolean
  accountIds: string[]
}

const useUpdateFeatureFlagMutation = () => {
  const queryClient = useQueryClient()
  const account = useAccount()

  return useMutation(
    async (input: UpdateFeatureFlagInput) => {
      const response = await api.post(`/provide/featureflags/${input.id}`, input)
      return response.data as FeatureFlag
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAdminFeatureFlags', account.id])
      }
    }
  )
}

export default useUpdateFeatureFlagMutation
