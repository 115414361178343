import { withAITracking } from '@microsoft/applicationinsights-react-js'
import useAdminFeatureFlagQuery, { type FeatureFlag } from '~/hooks/services/provide/queries/useAdminFeatureFlagQuery'

import { useEffect, useState } from 'react'

import { stopLoading } from 'src/actions/loadingActions'

import Banner from 'src/components/Common/Banner'
import Button from 'src/components/Common/Button'
import Section from 'src/components/Common/Section'
import FeatureFlagDrawer from 'src/components/Drawers/FeatureFlagDrawer'
import AdminFeatureFlagsTable from 'src/components/Tables/AdminFeatureFlagsTable'

import { reactPlugin } from 'src/configs/appInsights'

import { useDispatch } from 'react-redux'

const AdminFeatureFlags: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => void dispatch(stopLoading()), [dispatch])

  const { data: featureFlags } = useAdminFeatureFlagQuery()
  const [selectedFlag, setSelectedFlag] = useState<FeatureFlag | null>(null)

  const handleCreateFlag = () => {
    setSelectedFlag({
      id: '',
      name: '',
      description: '',
      enabled: false,
      accountIds: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    })
  }

  return (
    <>
      <Section>
        <Banner align="right" className="mb-8">
          <Button onClick={handleCreateFlag} variant="primary">
            Create Feature Flag
          </Button>
        </Banner>
        <AdminFeatureFlagsTable flags={featureFlags ?? []} onEditFlag={setSelectedFlag} />
      </Section>

      <FeatureFlagDrawer onClose={() => setSelectedFlag(null)} featureFlag={selectedFlag} hidden={!selectedFlag} />
    </>
  )
}

const AdminFeatureFlagsWithTracking = withAITracking(reactPlugin, AdminFeatureFlags, 'AdminFeatureFlags')
export default AdminFeatureFlagsWithTracking
