import { type FunctionComponent, type ReactNode } from 'react'

import { FcAlarmClock, FcCancel, FcClock, FcDocument, FcHighPriority, FcKey, FcLeave, FcLowPriority, FcMediumPriority, FcOk, FcPaid } from 'react-icons/fc'

import { ReactComponent as SVGIconCoinsHigh } from '../../images/SalesPerfIconHigh.svg'

/* SVGs */
import { ReactComponent as SVGIconCoinsLow } from '../../images/SalesPerfIconLow.svg'
import { ReactComponent as SVGIconCoinsMedium } from '../../images/SalesPerfIconMedium.svg'

/* utils */
import { classNames } from '../../utils/classNames'

interface Props {
  state: string
}

const State: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { state } = props

  let icon: ReactNode
  let background: string
  let text: string
  let label: string = state

  switch (state) {
    case 'Low Impact':
      icon = <FcLowPriority className="h-full w-full" />
      background = 'bg-th-success-light'
      text = 'text-th-success'
      label = 'Low'
      break
    case 'Medium Impact':
      icon = <FcMediumPriority className="h-full w-full" />
      background = 'bg-th-warning-light'
      text = 'text-th-warning'
      label = 'Medium'
      break
    case 'High Impact':
      icon = <FcHighPriority className="h-full w-full" />
      background = 'bg-th-error-light'
      text = 'text-th-error'
      label = 'High'
      break
    case 'Small':
      icon = <SVGIconCoinsLow />
      background = 'bg-th-info-light'
      text = 'text-th-info'
      label = 'Less than £500'
      break
    case 'Medium':
      icon = <SVGIconCoinsMedium className="h-full w-full" />
      background = 'bg-th-info-light'
      text = 'text-th-info'
      label = 'Between £500 and £5000'
      break
    case 'Large':
      icon = <SVGIconCoinsHigh />
      background = 'bg-th-error-light'
      text = 'text-th-error'
      label = 'More than £5000'
      break
    case 'Open':
      icon = <FcDocument className="h-full w-full" />
      background = 'bg-th-info-light'
      text = 'text-th-info'
      break
    case 'Pending':
      icon = <FcClock className="h-full w-full" />
      background = 'bg-th-info-light'
      text = 'text-th-info'
      break
    case 'Awaiting Action':
      icon = <FcAlarmClock className="h-full w-full" />
      background = 'bg-th-error-light'
      text = 'text-th-error'
      break
    case 'Overdue':
    case 'Ended':
      icon = <FcLeave className="h-full w-full" />
      background = 'bg-th-error-light'
      text = 'text-th-error'
      break
    case 'Paid In Full':
      icon = <FcPaid className="h-full w-full" />
      background = 'bg-th-success-light'
      text = 'text-th-success'
      break
    case 'Not Registered':
      icon = <FcCancel className="h-full w-full" />
      background = 'bg-th-error-light'
      text = 'text-th-error'
      break
    case 'Admin':
      icon = <FcKey className="h-full w-full" />
      background = 'bg-th-warning-light'
      text = 'text-th-warning'
      break
    case 'Registered':
    case 'Closed':
    case 'Active':
    default:
      icon = <FcOk className="h-full w-full" />
      background = 'bg-th-success-light'
      text = 'text-th-success'
      break
  }

  return (
    <div className={classNames('flex w-max items-center rounded-full p-1 text-sm font-bold', background)}>
      <div className="h-5 w-5">{icon}</div>
      <span className={classNames('px-1', text)}>{label}</span>
    </div>
  )
}

export default State
