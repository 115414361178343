import * as React from 'react'

import { classNames } from 'src/utils/classNames'

import { PiInfo } from 'react-icons/pi'

import Popover from './Popover'

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  info?: string
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, label, info, ...props }, ref) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label htmlFor={props.name} className="ml-2 flex gap-1 text-th-text-secondary">
          {label}
          {props.required && <div className="text-th-error">*</div>}
          {info && (
            <Popover text={info} position="bottom">
              <PiInfo className="text-gray-500 mb-auto h-4 w-4 text-2xl" />
            </Popover>
          )}
        </label>
      )}
      <textarea
        maxLength={props.maxLength ?? 5000}
        className={classNames(
          'mb-4 rounded-lg border border-th-border bg-th-content p-2 font-body text-sm text-th-text disabled:pointer-events-none disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  )
})

TextArea.displayName = 'TextArea'

export default TextArea
