import '@microsoft/applicationinsights-shims'

import React from 'react'

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App'
import store from './configs/store'

/* css */
import './css/index.css'

/* includes */
import reportWebVitals from './reportWebVitals'

const element = document.getElementById('root')!
const root = createRoot(element)

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <div id="app">
        <App />
      </div>
    </React.StrictMode>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
