/* redux store */
import {
  closeDrawers,
  openAzureDeploymentDrawer,
  openBookSecurityReviewDrawer,
  openChangeAccountDetailsDrawer,
  openNewContactDrawer,
  openNewTicketDrawer,
  openRequestQuoteDrawer
} from '../actions/drawerActions'
import store from './store'

type IconlyIconKey = string // keyof Omit<typeof ReactIconly, 'Iconly' | 'IconlyProvider'>

export type RouteDefinition = {
  label: string
  icon: IconlyIconKey
  route?: string
  children?: ChildRouteDefinition[]
  tabs?: ChildRouteDefinition[]
  action?: {
    icon: IconlyIconKey
    label: string
    route?: string
    function?: () => void
  }
  hidden?: boolean
}

export type ChildRouteDefinition = Omit<RouteDefinition, 'children' | 'icon'> & { route: string }

export const routes: RouteDefinition[] = [
  {
    label: 'Dashboard',
    icon: 'Home',
    route: '/'
  },
  {
    label: 'Account',
    icon: 'User',
    route: undefined,
    children: [
      // { label: 'Overview', route: '/account' },
      {
        label: 'Contacts',
        route: '/contacts',
        action: {
          icon: 'AddUser',
          label: 'New Contact',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openNewContactDrawer())
          }
        }
      },
      { label: 'Invoices', route: '/invoices' },
      { label: 'Credit Notes', route: '/creditnotes' },
      {
        label: 'Quotes',
        route: '/quotes',
        action: {
          icon: 'Bookmark',
          label: 'Request Quote',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openRequestQuoteDrawer())
          }
        }
      },
      // { label: 'Products', route: '/products' },
      {
        label: 'Services',
        route: '/services',
        tabs: [
          { label: 'Services', route: '/services' },
          { label: 'Orders', route: '/services/orders' }
        ]
      },
      {
        label: 'My Account',
        route: '/account',
        action: {
          icon: 'Bookmark',
          label: 'Request Change',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openChangeAccountDetailsDrawer())
          }
        }
      },
      // { label: 'Usage', route: '/azure_usage' },
      {
        label: 'Documents',
        route: '/documents'
      }
    ]
  },
  {
    label: 'Azure',
    icon: 'Activity',
    route: undefined,
    children: [
      {
        label: 'Overview',
        route: '/azure_overview',
        tabs: [
          { label: 'Overview', route: '/azure_overview' },
          { label: 'Budgets', route: '/azure_overview/budgets' },
          { label: 'Recurring Spend', route: '/azure_overview/recurring' },
          { label: 'Resource Explorer', route: '/azure_overview/resource-explorer' },
          { label: 'Trends & Insights', route: '/azure_overview/trends-insights' },
          { label: 'Data Export', route: '/azure_overview/export' }
        ]
      },
      {
        label: 'Subscriptions',
        route: '/azure_subscriptions',
        action: {
          icon: 'Bookmark',
          label: 'New Subscription',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(
              openAzureDeploymentDrawer({
                additional_info_url: '',
                approval_workflow: 'auto',
                assignment_group: 'e1396093db2baa00591972fabf96191d',
                description_text: 'Create a new Azure Subscription with a name provided.',
                enabled: true,
                form_notes: '',
                index: 0,
                pipeline_id: '62',
                pipeline_short_name: 'CreateSubscription',
                price: '(1) Company size < 50 (£1,500)\n(2) Company size < 51- 200 (£2,652)\n(3) Company size < 201+ (See Azure Landing Zone)',
                resource_type: 'Azure Subscription',
                short_description: 'Deployment of a new Azure Subscription into Azure via CloudDirectClientDeploymentV2/CreateSubscription pipeline.',
                type: 'Automated Resource'
              })
            )
          }
        }
      },
      /* {
        label: 'Usage',
        route: '/azure_usage',
        tabs: [
          { label: 'By Subscriptions', route: '/azure_usage' },
          { label: 'By Resource', route: '/azure_usage/resources' }
        ]
      }, */
      {
        label: 'Advisor',
        route: '/azure_advisor',
        tabs: [
          { label: 'Overview', route: '/azure_advisor' },
          { label: 'Recommendations', route: '/azure_advisor/recommendations' }
        ]
      },
      {
        label: 'Deployments',
        route: '/azure_deployments',
        tabs: [
          { label: 'Deployments', route: '/azure_deployments' },
          { label: 'History', route: '/azure_deployments/history' }
        ]
      },
      {
        label: 'Emissions',
        route: '/azure_emissions',
        tabs: [
          { label: 'Overview', route: '/azure_emissions' },
          { label: 'Data Export', route: '/azure_emissions/export' }
        ]
      }
      // { label: 'Deploy Resources', route: '/deploy-resources' },
      // { label: 'Request Resources', route: '/request-resources' },
      // { label: 'Roadmap', route: '/azure-roadmap' },
      // { label: 'Security', route: '/azure-security' },
      // { label: 'Azure Links', route: '/azure-links' }
    ]
  },
  {
    label: 'Microsoft 365',
    icon: 'Bag2',
    route: undefined,
    children: [
      {
        label: 'Subscriptions',
        route: '/subscriptions'
      },
      {
        label: 'Products',
        route: '/products',
        action: {
          icon: 'Buy',
          label: 'Cart',
          route: '/cart'
        }
      },
      { label: 'Cart', route: '/cart' }
    ]
  },
  {
    label: 'Support',
    icon: 'Bookmark',
    route: undefined,
    children: [
      {
        label: 'Tickets',
        route: '/tickets',
        tabs: [
          {
            label: 'Overview',
            route: '/tickets/:id'
          }
        ],
        action: {
          icon: 'Bookmark',
          label: 'New Ticket',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openNewTicketDrawer())
          }
        }
      },
      { label: 'Training', route: '/training' }
      // { label: 'FAQ', route: '/faq' },
    ]
  },
  {
    label: 'Security',
    icon: 'Lock',
    route: undefined,
    children: [
      {
        label: 'Azure Secure Score',
        route: '/azure_secure_score',
        action: {
          icon: 'ShieldDone',
          label: 'Book Review',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openBookSecurityReviewDrawer('Azure'))
          }
        }
      },
      {
        label: 'M365 Secure Score',
        route: '/m365_secure_score',
        action: {
          icon: 'ShieldDone',
          label: 'Book Review',
          function: (): void => {
            store.dispatch(closeDrawers())
            store.dispatch(openBookSecurityReviewDrawer('M365'))
          }
        }
      }
    ]
  },
  // {
  //   label: 'Managed Services',
  //   icon: 'TimeCircle',
  //   route: '/managed-services'
  // },
  // {
  //   label: 'Resources',
  //   icon: 'Paper',
  //   route: undefined,
  //   children: [
  //     { label: 'Learning Resources', route: '/learning-resources' },
  //     { label: 'Events', route: '/events' },
  //     { label: 'News', route: '/news' },
  //     { label: 'Release Notes', route: '/release-notes' }
  //   ]
  // },
  {
    label: 'Profile',
    icon: 'Setting',
    route: '/profile',
    hidden: true
  },
  {
    label: 'Signing your agreement with Cloud Direct',
    icon: 'Setting',
    route: '/sign_quote',
    hidden: true
  }
]

export const adminRoutes: RouteDefinition[] = [
  {
    label: 'Admin',
    icon: 'Setting',
    route: undefined,
    children: [
      {
        label: 'Alerts',
        route: '/admin/alerts'
      },
      {
        label: 'Feature Flags',
        route: '/admin/feature-flags'
      }
    ]
  }
]

export const allRoutes: RouteDefinition[] = [...routes, ...adminRoutes]
