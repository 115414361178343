import * as React from 'react'

import { classNames } from 'src/utils/classNames'

import { PiInfo } from 'react-icons/pi'

import Popover from './Popover'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  info?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, label, info, ...props }, ref) => {
  const inputId = props.id ?? props.name ?? label

  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label htmlFor={inputId} className="ml-2 flex gap-1 text-th-text-secondary">
          {label}
          {props.required && (
            <div aria-hidden="true" className="text-th-error">
              *
            </div>
          )}
          {info && (
            <Popover text={info} position="bottom">
              <button type="button" aria-label={`${label} information`} className="bg-transparent text-gray-500 mb-auto h-4 w-4 border-none p-0 text-2xl">
                <PiInfo aria-hidden="true" />
              </button>
            </Popover>
          )}
        </label>
      )}
      <input
        name={props.name ?? label}
        id={inputId}
        type={type}
        className={classNames(
          'rounded-full border border-th-border bg-th-content px-4 py-2 font-body text-sm text-th-text disabled:pointer-events-none disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  )
})

Input.displayName = 'Input'

export default Input
