import { classNames } from 'src/utils/classNames'

import { createPortal } from 'react-dom'
import { IoClose } from 'react-icons/io5'

type PortalDrawerProps = {
  title?: string
  subtitle?: string
  onClose: () => void
  children?: React.ReactNode
  loading?: boolean
  hidden?: boolean
  actions?: React.ReactNode
}

const PortalDrawer: React.FC<PortalDrawerProps> = ({ title, onClose, children, subtitle, loading, hidden, actions }) => {
  return createPortal(
    <aside
      className={classNames(
        'fixed right-0 top-0 z-50 flex h-full w-full max-w-lg flex-col border-l border-th-border bg-th-content py-4 text-th-text xs:py-8',
        hidden && 'hidden'
      )}
    >
      <div className="flex items-center justify-between px-4 text-th-text xs:px-8">
        <div>
          <span className="font-headline text-3xl font-bold">{title}</span>
          <span className="block font-body text-sm text-th-text-secondary">{subtitle}</span>
          {loading && subtitle && <div className="mt-1 h-4 w-1/2 animate-pulse rounded-md bg-th-border" />}
        </div>

        <button onClick={onClose} className="h-6 w-6">
          <IoClose className="h-6 w-6" />
        </button>
      </div>

      <hr className="mx-4 mt-8 border-th-border xs:mx-8" />

      <div className={`px-4 pt-8 xs:px-8 ${actions ? 'pb-8' : ''} grow overflow-y-auto`}>{children}</div>
      {actions && (
        <div className="px-4 xs:px-8">
          <hr className="mb-8 border-th-border" />
          {actions}
        </div>
      )}
    </aside>,
    document.querySelector('body')!
  )
}

export default PortalDrawer
